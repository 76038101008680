import _react2 from "react";
import _styled2 from "@emotion/styled";
import _reactPopper2 from "react-popper";
import _styledView2 from "styled-view";
import { dew as _Swatch2Dew } from "./Swatch";
import { dew as _Picker2Dew } from "./Picker";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ColorPicker = ColorPicker;

  var _react = _interopRequireWildcard(_react2);

  var _styled = _interopRequireDefault(_styled2);

  var _reactPopper = _reactPopper2;
  var _styledView = _styledView2;

  var _Swatch = _Swatch2Dew();

  var _Picker = _Picker2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function _getRequireWildcardCache() {
    if (typeof WeakMap !== "function") return null;
    var cache = new WeakMap();

    _getRequireWildcardCache = function _getRequireWildcardCache() {
      return cache;
    };

    return cache;
  }

  function _interopRequireWildcard(obj) {
    if (obj && obj.__esModule) {
      return obj;
    }

    if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
      return {
        default: obj
      };
    }

    var cache = _getRequireWildcardCache();

    if (cache && cache.has(obj)) {
      return cache.get(obj);
    }

    var newObj = {};
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }

    newObj.default = obj;

    if (cache) {
      cache.set(obj, newObj);
    }

    return newObj;
  }

  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);

    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }

    return keys;
  }

  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};

      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          _defineProperty(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }

    return target;
  }

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
  }

  function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance");
  }

  function _iterableToArrayLimit(arr, i) {
    if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
      return;
    }

    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"] != null) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
  }

  function ColorPicker(props) {
    var _useState = (0, _react.useState)(false),
        _useState2 = _slicedToArray(_useState, 2),
        isOpen = _useState2[0],
        setIsOpen = _useState2[1];

    var value = props.value,
        onChange = props.onChange;
    var popperRef = (0, _react.useRef)(null);
    useOpenCloseHandlers(popperRef, isOpen, setIsOpen);

    var handleOnChange = function (data) {
      onChange && onChange(data.hex);
    };

    var openPopper = function () {
      return setIsOpen(!isOpen);
    };

    return _react.default.createElement(_reactPopper.Manager, null, _react.default.createElement(_reactPopper.Reference, null, function (_ref) {
      var ref = _ref.ref;
      return _react.default.createElement(_Swatch.Swatch, {
        ref: ref,
        onClick: openPopper,
        value: value
      });
    }), isOpen && _react.default.createElement(_reactPopper.Popper, {
      placement: "right-start",
      modifiers: {
        preventOverflow: {
          boundariesElement: "window"
        }
      }
    }, function (_ref2) {
      var ref = _ref2.ref,
          style = _ref2.style,
          placement = _ref2.placement;
      return _react.default.createElement("div", {
        ref: popperRef
      }, _react.default.createElement("div", {
        ref: ref,
        style: _objectSpread({}, style, {
          zIndex: 8
        }),
        "data-placement": placement
      }, _react.default.createElement(_Picker.Picker, {
        disableAlpha: true,
        color: value,
        onChangeComplete: handleOnChange
      })));
    }));
  }

  function useOpenCloseHandlers(ref, isOpen, setIsOpen) {
    (0, _react.useEffect)(function () {
      var node = ref.current;

      var handleBodyClick = function (event) {
        var target = event.target;

        if (node !== target && !node.contains(target)) {
          setIsOpen(false);
        }
      };

      var handleOnEscPress = function (event) {
        if (isOpen && event.keyCode === 27) {
          setIsOpen(false);
        }
      };

      if (node && isOpen) {
        document.addEventListener("click", handleBodyClick);
        document.addEventListener("keydown", handleOnEscPress);
      }

      return function () {
        if (node && isOpen) {
          document.removeEventListener("click", handleBodyClick);
          document.removeEventListener("keydown", handleOnEscPress);
        }
      };
    }, [ref, isOpen, setIsOpen]);
  }

  return exports;
}