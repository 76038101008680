import _styledBase2 from "@emotion/styled-base";
import _react2 from "react";
import _core2 from "@emotion/core";
import _tinycolor2 from "tinycolor2";
import _reactColor2 from "react-color";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Picker = Picker;

  var _styledBase = _interopRequireDefault(_styledBase2);

  var _react = _interopRequireDefault(_react2);

  var _core = _core2;

  var _tinycolor = _interopRequireDefault(_tinycolor2);

  var _reactColor = _reactColor2;

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function _EMOTION_STRINGIFIED_CSS_ERROR__() {
    return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
  }

  function Picker(props) {
    var isLight = (0, _tinycolor.default)(props.color).isLight();
    return _react.default.createElement(ColorPickerWrapper, {
      isLight: isLight
    }, _react.default.createElement(_reactColor.SketchPicker, Object.assign({
      disableAlpha: true
    }, props)));
  }

  var _ref = {
    name: "xil85b",
    styles: "--borderColor:rgba(0,0,0,0.3);"
  };
  var _ref2 = {
    name: "102ndhf",
    styles: "--borderColor:rgba(255,255,255,0.3);"
  };

  var isLight = function (_ref3) {
    var isLight = _ref3.isLight;

    if (isLight) {
      return _ref;
    } else {
      return _ref2;
    }
  };

  var ColorPickerWrapper = (0, _styledBase.default)("div", {
    target: "ep79ek40",
    label: "ColorPickerWrapper"
  })(isLight, ";.sketch-picker{background:white !important;border:1px solid black !important;border-radius:0px !important;color:black !important;font-family:inherit;user-select:none;*{color:black !important;user-select:none;}input{background:white !important;border:1px solid black !important;box-shadow:none !important;user-select:initial;}.flexbox-fix{&:last-child{border-top:1px solid black !important;}}}" + "");
  return exports;
}