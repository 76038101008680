import _react2 from "react";
import _reactUseportal2 from "react-useportal";
import { dew as _BaseControls2Dew } from "./BaseControls";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Controls = Controls;

  var _react = _interopRequireDefault(_react2);

  var _reactUseportal = _interopRequireDefault(_reactUseportal2);

  var _BaseControls = _BaseControls2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }
  /**
   * The default and portal'ed version of the <Controls /> UI.
   */


  function Controls(props) {
    var _usePortal = (0, _reactUseportal.default)(),
        Portal = _usePortal.Portal;

    return _react.default.createElement(Portal, null, _react.default.createElement(_BaseControls.BaseControls, props));
  }

  return exports;
}