import _react2 from "react";
import { dew as _ColorPicker2Dew } from "../ColorPicker";
import { dew as _Field2Dew } from "./Field.styles";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.BooleanField = BooleanField;
  exports.ColorField = ColorField;
  exports.DateField = DateField;
  exports.NumberField = NumberField;
  exports.RangeField = RangeField;
  exports.SelectField = SelectField;
  exports.TextField = TextField;
  exports.TextAreaField = TextAreaField;
  exports.FieldVariants = void 0;

  var _react = _interopRequireDefault(_react2);

  var _ColorPicker = _ColorPicker2Dew();

  var _Field = _Field2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};

    var target = _objectWithoutPropertiesLoose(source, excluded);

    var key, i;

    if (Object.getOwnPropertySymbols) {
      var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

      for (i = 0; i < sourceSymbolKeys.length; i++) {
        key = sourceSymbolKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
        target[key] = source[key];
      }
    }

    return target;
  }

  function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;

    for (i = 0; i < sourceKeys.length; i++) {
      key = sourceKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      target[key] = source[key];
    }

    return target;
  }

  var FieldVariants = {
    boolean: BooleanField,
    color: ColorField,
    date: DateField,
    number: NumberField,
    select: SelectField,
    range: RangeField,
    text: TextField,
    textarea: TextAreaField
  };
  exports.FieldVariants = FieldVariants;

  function BooleanField(props) {
    var value = props.value,
        onChange = props.onChange;
    return _react.default.createElement(_Field.BaseInputField, Object.assign({}, props, {
      type: "checkbox",
      checked: value,
      onChange: function handleOnChange(event) {
        onChange && onChange(event.target.checked);
      },
      padding: 0,
      margin: 0
    }));
  }

  function ColorField(props) {
    return _react.default.createElement(_ColorPicker.ColorPicker, props);
  }

  function DateField(props) {
    return _react.default.createElement(TextField, Object.assign({}, props, {
      type: "date"
    }));
  }

  function NumberField(props) {
    return _react.default.createElement(TextField, props);
  }

  function RangeField(props) {
    var options = props.options,
        restProps = _objectWithoutProperties(props, ["options"]);

    return _react.default.createElement(TextField, Object.assign({}, restProps, options, {
      type: "range"
    }));
  }

  function SelectField(props) {
    var options = props.options,
        onChange = props.onChange,
        restProps = _objectWithoutProperties(props, ["options", "onChange"]);

    var handleOnChange = createBaseHandleOnChange(onChange);
    var items = Object.keys(options).reduce(function (collection, key) {
      return [].concat(collection, [{
        label: key,
        key: key,
        value: options[key]
      }]);
    }, []);
    return _react.default.createElement(_Field.BaseInputField, Object.assign({
      as: "select"
    }, restProps, {
      onChange: handleOnChange
    }), items.map(function (item) {
      return _react.default.createElement("option", item);
    }));
  }

  function TextField(props) {
    var onChange = props.onChange;
    var handleOnChange = createBaseHandleOnChange(onChange);
    return _react.default.createElement(_Field.BaseInputField, Object.assign({}, props, {
      onChange: handleOnChange
    }));
  }

  function TextAreaField(props) {
    return _react.default.createElement(TextField, Object.assign({}, props, {
      as: "textarea",
      resize: "vertical",
      minHeight: 19,
      rows: 3
    }));
  }

  function createBaseHandleOnChange(onChange) {
    return function (event) {
      onChange && onChange(event.target.value);
    };
  }

  return exports;
}