import _styledBase2 from "@emotion/styled-base";
import _react2 from "react";
import _styledView2 from "styled-view";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Swatch = void 0;

  var _styledBase = _interopRequireDefault(_styledBase2);

  var _react = _interopRequireDefault(_react2);

  var _styledView = _styledView2;

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};

    var target = _objectWithoutPropertiesLoose(source, excluded);

    var key, i;

    if (Object.getOwnPropertySymbols) {
      var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

      for (i = 0; i < sourceSymbolKeys.length; i++) {
        key = sourceSymbolKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
        target[key] = source[key];
      }
    }

    return target;
  }

  function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;

    for (i = 0; i < sourceKeys.length; i++) {
      key = sourceKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      target[key] = source[key];
    }

    return target;
  }

  function _EMOTION_STRINGIFIED_CSS_ERROR__() {
    return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop).";
  }

  var Swatch = _react.default.forwardRef(function (props, ref) {
    var value = props.value,
        restProps = _objectWithoutProperties(props, ["value"]);

    return _react.default.createElement(SwatchButton, Object.assign({
      type: "button"
    }, restProps, {
      ref: ref,
      tabIndex: 0
    }), _react.default.createElement(_styledView.View, {
      borderRadius: 2,
      height: 16,
      style: {
        backgroundColor: value
      },
      boxShadow: "0 0 0 1px rgba(0, 0, 0, 0.2) inset"
    }));
  });

  exports.Swatch = Swatch;
  var SwatchButton = (0, _styledBase.default)("button", {
    target: "e1yloobg0",
    label: "SwatchButton"
  })({
    name: "1dgttia",
    styles: "appearance:none;border:1px solid black;box-sizing:border-box;cursor:pointer;display:block;max-width:50px;padding:3px;width:100%;"
  });
  return exports;
}