import _react2 from "react";
import _styledView2 from "styled-view";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.BaseInputField = BaseInputField;
  exports.Label = Label;
  exports.FieldGroup = FieldGroup;

  var _react = _interopRequireDefault(_react2);

  var _styledView = _styledView2;

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};

    var target = _objectWithoutPropertiesLoose(source, excluded);

    var key, i;

    if (Object.getOwnPropertySymbols) {
      var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

      for (i = 0; i < sourceSymbolKeys.length; i++) {
        key = sourceSymbolKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
        target[key] = source[key];
      }
    }

    return target;
  }

  function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;

    for (i = 0; i < sourceKeys.length; i++) {
      key = sourceKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      target[key] = source[key];
    }

    return target;
  }

  function BaseInputField(props) {
    return _react.default.createElement(_styledView.View, Object.assign({
      as: "input",
      autoComplete: "off",
      backgroundColor: "transparent",
      border: "1px solid var(--controlPanelBorderColor)",
      color: "inherit",
      display: "block",
      fontFamily: "inherit",
      fontSize: "inherit",
      padding: 2,
      type: "text",
      width: "100%"
    }, props));
  }

  function Label(props) {
    var children = props.children,
        label = props.label,
        restProps = _objectWithoutProperties(props, ["children", "label"]);

    return _react.default.createElement(_styledView.View, Object.assign({
      as: "label"
    }, restProps), _react.default.createElement(_styledView.View, {
      fontSize: "0.9em",
      marginBottom: 2,
      opacity: 0.6
    }, label), children);
  }

  function FieldGroup(props) {
    return _react.default.createElement(_styledView.View, Object.assign({
      marginBottom: 8
    }, props));
  }

  return exports;
}