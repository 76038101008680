import _tinycolor2 from "tinycolor2";
import _is2 from "@itsjonq/is";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.toValue = toValue;
  exports.toBoolean = toBoolean;
  exports.toColor = toColor;
  exports.toDate = toDate;
  exports.toNumber = toNumber;

  var _tinycolor = _interopRequireDefault(_tinycolor2);

  var _is = _is2;

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }
  /**
   * The base "transformValue" method. This library wraps the value returns in a
   * transformValue method to dynamically transform them, if required.
   *
   * For example, the color() knob requires the value to be transformed into
   * a valid hex value (#eeeeee). Therefore, the (raw) value needs to run
   * through a specific color transformer.
   *
   * @param {any} value The initial value.
   * @returns {any} The value, unaffected.
   */


  function toValue(value) {
    return value;
  }
  /**
   * Transforms a value to a valid boolean.
   * @param {any} value The initial value.
   * @returns {boolean} The boolean value.
   */


  function toBoolean(value) {
    if (_is.is.boolean(value)) return value;

    if (_is.is.string(value)) {
      var boolean = value.toLowerCase();
      return boolean === "true";
    }

    return false;
  }
  /**
   * Transforms a value to a valid hex code value.
   * @param {any} value The initial value.
   * @returns {string} A hex code value.
   */


  function toColor(value) {
    return (0, _tinycolor.default)(value).toHexString();
  }
  /**
   * Transforms a value to a valid date value.
   * @param {any} value The initial value.
   * @returns {string} A YYYY-MM-DD date value.
   */


  function toDate(value) {
    return new Date(value).toISOString().slice(0, 10);
  }
  /**
   * Transforms a value to a valid number.
   * @param {any} value The initial value.
   * @returns {number} A number value.
   */


  function toNumber(value) {
    if (_is.is.number(value)) return value;
    if (_is.is.string(value) && !value.length) return value;
    var number = parseFloat(value);
    return isNaN(number) ? 0 : number;
  }

  return exports;
}