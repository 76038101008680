import { dew as _store2Dew } from "./store";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.getFields = getFields;
  exports.getField = getField;
  exports.getValue = getValue;

  var _store = _store2Dew();
  /**
   * Retrieves the fields from the store.
   * @returns {Array<Object>} Fields from the store.
   */


  function getFields() {
    return [].concat(_store.store.getState().fields) || [];
  }
  /**
   * Retrieves a single field from the store, based on the prop (key).
   * @param {string} prop The prop to find from the store.
   * @returns {Object} A field from the store.
   */


  function getField(prop) {
    if (!prop) return undefined;
    return getFields().find(function (f) {
      return f.prop === prop;
    });
  }
  /**
   * Retrieves the value of a single field from the store, based on the prop (key).
   * @param {string} prop The prop to find from the store.
   * @returns {any} The value of the field.
   */


  function getValue(prop) {
    if (!prop) return undefined;
    var field = getField(prop);
    return field ? field.value : undefined;
  }

  return exports;
}