import _react2 from "react";
import { dew as _store2Dew } from "../../store";
import { dew as _hooks2Dew } from "../../hooks";
import { dew as _Field2Dew } from "../Field";
import { dew as _Controls2Dew } from "./Controls.styles";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.BaseControls = BaseControls;

  var _react = _interopRequireDefault(_react2);

  var _store = _store2Dew();

  var _hooks = _hooks2Dew();

  var _Field = _Field2Dew();

  var _Controls = _Controls2Dew();

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function _objectWithoutProperties(source, excluded) {
    if (source == null) return {};

    var target = _objectWithoutPropertiesLoose(source, excluded);

    var key, i;

    if (Object.getOwnPropertySymbols) {
      var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

      for (i = 0; i < sourceSymbolKeys.length; i++) {
        key = sourceSymbolKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
        target[key] = source[key];
      }
    }

    return target;
  }

  function _objectWithoutPropertiesLoose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;

    for (i = 0; i < sourceKeys.length; i++) {
      key = sourceKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      target[key] = source[key];
    }

    return target;
  }
  /**
   * <BaseControls /> was separated from the preferred <Controls /> component
   * to provide a non-portaled way to render and test the component.
   */


  function BaseControls(props) {
    var _useControls = (0, _hooks.useControls)(),
        fields = _useControls.fields;

    if (!fields.length) return _react.default.createElement("div", null);

    var isDark = props.isDark,
        title = props.title,
        padding = props.padding,
        restProps = _objectWithoutProperties(props, ["isDark", "title", "padding"]);

    var handleOnChange = function (prop) {
      return function (nextValue) {
        (0, _store.updateField)(prop, nextValue);
      };
    };

    return _react.default.createElement(_Controls.Wrapper, Object.assign({}, restProps, {
      isDark: isDark
    }), _react.default.createElement(_Controls.Header, null, title || "Control Panel"), _react.default.createElement(_Controls.Body, {
      padding: padding || 8
    }, fields.map(function (field) {
      return _react.default.createElement(_Field.Field, Object.assign({}, field, {
        key: field.prop,
        onChange: handleOnChange(field.prop)
      }));
    })));
  }

  return exports;
}