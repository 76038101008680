import { dew as _store2Dew } from "../store";
import { dew as _transformValue2Dew } from "./transformValue";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.boolean = boolean;
  exports.color = color;
  exports.date = date;
  exports.number = number;
  exports.range = range;
  exports.select = select;
  exports.text = text;
  exports.textarea = textarea;

  var _store = _store2Dew();

  var _transformValue = _transformValue2Dew();
  /**
   * A knob to create and control a boolean value.
   * @param {string} prop The key (name) for the field.
   * @param {boolean} value The value of the field.
   * @returns {boolean} The value of the field.
   */


  function boolean(prop, value) {
    (0, _store.addField)({
      prop: prop,
      type: "boolean",
      value: value,
      transformValue: _transformValue.toBoolean
    });
    return (0, _store.getValue)(prop);
  }
  /**
   * A knob to create and control a color value.
   * @param {string} prop The key (name) for the field.
   * @param {string} value The value of the field.
   * @returns {string} The value of the field.
   */


  function color(prop, value) {
    (0, _store.addField)({
      prop: prop,
      value: value,
      type: "color",
      transformValue: _transformValue.toColor
    });
    return (0, _store.getValue)(prop);
  }
  /**
   * A knob to create and control a date value.
   * @param {string} prop The key (name) for the field.
   * @param {string} value The value of the field.
   * @returns {string} The value of the field.
   */


  function date(prop, value, options) {
    (0, _store.addField)({
      prop: prop,
      value: value,
      type: "date",
      options: options,
      transformValue: _transformValue.toDate
    });
    return (0, _store.getValue)(prop);
  }
  /**
   * A knob to create and control a number value.
   * @param {string} prop The key (name) for the field.
   * @param {number} value The value of the field.
   * @returns {number} The value of the field.
   */


  function number(prop, value) {
    (0, _store.addField)({
      prop: prop,
      value: value,
      type: "number",
      transformValue: _transformValue.toNumber
    });
    return (0, _store.getValue)(prop);
  }
  /**
   * A knob to create and control a range value.
   * @param {string} prop The key (name) for the field.
   * @param {number} value The value of the field.
   * @param {Object} options Options used for an input[type="range"].
   * @returns {number} The value of the field.
   */


  function range(prop, value, options) {
    (0, _store.addField)({
      prop: prop,
      value: value,
      type: "range",
      options: options,
      transformValue: _transformValue.toNumber
    });
    return (0, _store.getValue)(prop);
  }
  /**
   * A knob to create and control a select value.
   * @param {string} prop The key (name) for the field.
   * @param {any} value The value of the field.
   * @param {Object} options Options used to render a <select>...</select>
   * @returns {any} The value of the field.
   */


  function select(prop, options, value) {
    (0, _store.addField)({
      prop: prop,
      value: value,
      type: "select",
      options: options
    });
    return (0, _store.getValue)(prop);
  }
  /**
   * A knob to create and control a text value.
   * @param {string} prop The key (name) for the field.
   * @param {string} value The value of the field.
   * @returns {string} The value of the field.
   */


  function text(prop, value) {
    (0, _store.addField)({
      prop: prop,
      value: value,
      type: "text"
    });
    return (0, _store.getValue)(prop);
  }
  /**
   * A knob to create and control a textarea value.
   * @param {string} prop The key (name) for the field.
   * @param {string} value The value of the field.
   * @returns {string} The value of the field.
   */


  function textarea(prop, value) {
    (0, _store.addField)({
      prop: prop,
      value: value,
      type: "textarea"
    });
    return (0, _store.getValue)(prop);
  }

  return exports;
}