import _react2 from "react";
import { dew as _store2Dew } from "../store";
import { dew as _knobsDew } from "../knobs";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.useControls = useControls;
  var _react = _react2;

  var _store = _store2Dew();

  var knobs = _interopRequireWildcard(_knobsDew());

  function _getRequireWildcardCache() {
    if (typeof WeakMap !== "function") return null;
    var cache = new WeakMap();

    _getRequireWildcardCache = function _getRequireWildcardCache() {
      return cache;
    };

    return cache;
  }

  function _interopRequireWildcard(obj) {
    if (obj && obj.__esModule) {
      return obj;
    }

    if (obj === null || typeof obj !== "object" && typeof obj !== "function") {
      return {
        default: obj
      };
    }

    var cache = _getRequireWildcardCache();

    if (cache && cache.has(obj)) {
      return cache.get(obj);
    }

    var newObj = {};
    var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

    for (var key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

        if (desc && (desc.get || desc.set)) {
          Object.defineProperty(newObj, key, desc);
        } else {
          newObj[key] = obj[key];
        }
      }
    }

    newObj.default = obj;

    if (cache) {
      cache.set(obj, newObj);
    }

    return newObj;
  }

  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);

    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }

    return keys;
  }

  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};

      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          _defineProperty(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }

    return target;
  }

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _slicedToArray(arr, i) {
    return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest();
  }

  function _nonIterableRest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance");
  }

  function _iterableToArrayLimit(arr, i) {
    if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) {
      return;
    }

    var _arr = [];
    var _n = true;
    var _d = false;
    var _e = undefined;

    try {
      for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
        _arr.push(_s.value);

        if (i && _arr.length === i) break;
      }
    } catch (err) {
      _d = true;
      _e = err;
    } finally {
      try {
        if (!_n && _i["return"] != null) _i["return"]();
      } finally {
        if (_d) throw _e;
      }
    }

    return _arr;
  }

  function _arrayWithHoles(arr) {
    if (Array.isArray(arr)) return arr;
  }
  /**
   * A special hook that "connects" the store with a rendered component.
   * The hook callback arguments provide knobs that allow for the user
   * to add fields to the store.
   */


  function useControls() {
    var _useState = (0, _react.useState)((0, _store.getFields)()),
        _useState2 = _slicedToArray(_useState, 2),
        fields = _useState2[0],
        setFields = _useState2[1];

    var ref = (0, _react.useRef)(false);
    (0, _react.useEffect)(function () {
      var updateState = function () {
        return setFields((0, _store.getFields)());
      };
      /**
       * Forces an update during the initial render phase.
       * This is required after the initial connection to the store.
       * Otherwise, the added fields do not show on first render.
       */


      if (!ref.current) {
        ref.current = true;
        updateState();
      }
      /**
       * A subscription is established to the store to "connect" to store
       * state together with this hook state.
       */


      _store.store.subscribe(updateState);

      return function () {
        /**
         * The default behaviour is to remove all fields within the store
         * the moment the component is unmounted from view.
         */
        (0, _store.resetFields)();
        /**
         * The subscription is removed from the store on unmount.
         */

        _store.store.unsubscribe(updateState);
      };
    }, [ref, setFields]);
    var attributes = mapStateToProps(fields);
    return _objectSpread({}, knobs, {
      fields: fields,
      attributes: attributes
    });
  }
  /**
   * Remaps the collection of fields into a Object of key/value pairs.
   * @param {Array<Object>} state The fields.
   * @returns {Object}
   */


  function mapStateToProps(state) {
    return state.reduce(function (props, item) {
      var _objectSpread2;

      return _objectSpread({}, props, (_objectSpread2 = {}, _objectSpread2[item.prop] = item.value, _objectSpread2));
    }, {});
  }

  return exports;
}