var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.createUniqueIdFactory = createUniqueIdFactory;
  /**
   * Creates a factory to generate unique IDs
   * @param {string} prefix A prefix to add to the generated ID
   * @returns {string} The generated unique IDs
   */

  function createUniqueIdFactory(prefix) {
    var index = 0;
    return function () {
      index++;
      return prefix ? prefix + "-" + index++ : index;
    };
  }

  return exports;
}