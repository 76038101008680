import _react2 from "react";
import _styledView2 from "styled-view";
var exports = {},
    _dewExec = false;
export function dew() {
  if (_dewExec) return exports;
  _dewExec = true;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.Wrapper = Wrapper;
  exports.Header = Header;
  exports.Body = Body;

  var _react = _interopRequireDefault(_react2);

  var _styledView = _styledView2;

  function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
      default: obj
    };
  }

  function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);

    if (Object.getOwnPropertySymbols) {
      var symbols = Object.getOwnPropertySymbols(object);
      if (enumerableOnly) symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
      keys.push.apply(keys, symbols);
    }

    return keys;
  }

  function _objectSpread(target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i] != null ? arguments[i] : {};

      if (i % 2) {
        ownKeys(Object(source), true).forEach(function (key) {
          _defineProperty(target, key, source[key]);
        });
      } else if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
      } else {
        ownKeys(Object(source)).forEach(function (key) {
          Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
      }
    }

    return target;
  }

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function Wrapper(props) {
    var isDark = props.isDark,
        _props$style = props.style,
        styleProp = _props$style === void 0 ? {} : _props$style;
    var backgroundColor = isDark ? "black" : "white";
    var borderColor = isDark ? "white" : "black";

    var componentStyles = _objectSpread({}, styleProp, {
      "--controlPanelBackgroundColor": backgroundColor,
      "--controlPanelBorderColor": borderColor
    });

    return _react.default.createElement(_styledView.View, Object.assign({}, {
      fontFamily: "SFMono-Regular,Consolas,Liberation Mono,Menlo,monospace",
      border: "2px solid",
      backgroundColor: "var(--controlPanelBackgroundColor)",
      borderColor: "var(--controlPanelBorderColor)",
      color: "var(--controlPanelBorderColor)",
      fontSize: 11,
      position: "fixed",
      top: 8,
      right: 8,
      maxHeight: "calc(100% - 16px)",
      zIndex: 99999999,
      width: 200,
      flexDirection: "column",
      display: "flex"
    }, props, {
      style: componentStyles
    }));
  }

  function Header(props) {
    return _react.default.createElement(_styledView.View, Object.assign({
      fontWeight: "bold",
      padding: "6px 8px",
      borderBottom: "2px solid var(--controlPanelBorderColor)",
      textTransform: "uppercase"
    }, props));
  }

  function Body(props) {
    return _react.default.createElement(_styledView.View, Object.assign({
      overflowY: "auto",
      flex: 1
    }, props));
  }

  return exports;
}